import { OLD_UNITS } from '../fc/Constants/old-units'

const REDIRECTIONS = {
  'pdf-compressor': 'compress-pdf',
  'ebook-to-pdf': 'pdf-Converter'
}
export default function ({ req, route, $axios, res, redirect }) {
  const id = route.params.id

  if (REDIRECTIONS[id]) { redirect(301, '/' + REDIRECTIONS[id]) }

  if (OLD_UNITS.includes(id)) { redirect(301, '/unit/' + id) }
}
