export function getSettings(advancedOptions) {
    const hidden = []
    const selection = {}
    const fieldNames = {}

    function parseChildren(field) {
        if (field.hidden) {
            hidden.push(field.name)
        }
        fieldNames[field.id] = field.name

        if (field.type === 'checkbox') {
            selection[field.id] = field.default_value === '__checked__' || field.default_value === 'true' || field.default_value === true
        } else if (field.type === 'slider') {
            selection[field.id] = parseInt(field.default_value)
        } else if (['select', 'radio', 'radio_button'].includes(field.type)) {
            field.metas.forEach((meta) => {
                if (meta.is_default) {
                    selection[field.id] = meta._id
                }
                fieldNames[meta._id] = meta.value
            })
        } else if (field.default_value !== '') {
            selection[field.id] = field.default_value
        }
        if (field.children) {
            field.children.map(parseChildren)
        }
    }
    for (const g in advancedOptions) {
        if (advancedOptions[g].options) {
            advancedOptions[g].options.map(parseChildren)
        }
    }
    return {
        fieldNames,
        hidden,
        selection,
        list: advancedOptions
    }
}

export function childValidator(field, selection) {
    if (!selection) { return false }
    if (selection[field.id] !== undefined && field.children && field.children.length) {
        return field.children.filter((child) => {
            let valid = false
            if (typeof child.parent_values[0] === 'boolean') {
                valid = selection[field.id] === child.parent_values[0]
            } else {
                valid = child.parent_values.includes(selection[field.id])
            }
            return valid
        })
    }
    return false
}

export function parentValidator(field, selection) {
    const isValid = (condition) => {
        if (condition.optional && selection[condition.option] === undefined) {
            return true
        }
        return condition.meta.includes(selection[condition.option])
    }
    if (!field.conditions || !field.conditions.length || field.conditions.every(isValid)) {
        return true
    }
    return false
}
/**
 * 
 * @param {*} list 
 * @param {*} selection 
 * @param {*} full | Whether to return all the options present
 * in the UI or just the options needed for job creation.
 * @returns 
 */
export function parseSelection(list, selection, full = false) {
    const finalSelection = {}
    if (!selection) { return false }
    const parseOption = (option, selection) => {
        if(full) finalSelection[option.name] = option.metas && option.metas.length
            ? option.metas?.find(m => m._id === selection[option.id])?.value || null
            : selection[option.id]
        if (!option.hidden && parentValidator(option, selection)) {
            if (option.metas && option.metas.length) {
                option.metas.forEach((meta) => {
                    if (selection && (meta._id === selection[option.id])) finalSelection[option.name] = meta.value
                })
            } else {
                finalSelection[option.name] = selection[option.id]
            }
        }
        if (option.children && option.children.length) {
            const childs = childValidator(option, selection)
            if (childs && Array.isArray(childs)) { childs.forEach(child => parseOption(child, selection)) }
        }
    }
    if (Array.isArray(list)) {
        list.forEach((ao) => {
            if (ao.options && ao.options.length) {
                ao.options.forEach(option => parseOption(option, selection))
            }
        })
    }
    if (finalSelection.subtitle && finalSelection.subtitle.content) {
        finalSelection.subtitle = finalSelection.subtitle.content
    }
    /**
     * We are checking the image rotation advanced option
     * manually as we don't serve it from the backend. We 
     * have a UI button that sets the image rotation option
     * and if it's set, we simply send it with the request.
     */
    if (selection && selection['image_rotation'] != undefined && selection['image_rotation'] != '0') {
        finalSelection['image_rotation'] = selection['image_rotation']
    }
    return finalSelection
}

export function checkIfOptionExistsForAllFiles(optionID) {
    const userSettings = { ...$nuxt.$store.state.advancedSetting.userData.allSelection }
    delete userSettings.root
    delete userSettings.page

    let mergeOptionCount = 0

    for (const childObjectKey in userSettings) {
        if (userSettings.hasOwnProperty(childObjectKey)) {
            const childObject = userSettings[childObjectKey];
            if (childObject.hasOwnProperty(optionID)) {
                mergeOptionCount++
            }
        }
    }
    return mergeOptionCount > 1 && mergeOptionCount === Object.keys(userSettings).length;
}
