/**
 * We have similar statuses in fc-payment service too
 * Please make sure to update that service also when you update here
 */
export const PENDING_REMOTE_STATUSES = [
  "approval_pending",
  "incomplete",
  "past_due",
  "payer_action_required",
  "pending",
  "requires_confirmation",
  "requires_payment_method",
];

export const EVENT_BUS = {
  CANCEL_UPLOAD: "CANCEL_UPLOAD",
  RESTART_STUCK_JOB_WATCHER: "RESTART_STUCK_JOB_WATCHER",
};

export const DMCA_COPYRIGHTED_SOURCES = [
  "youtube.com",
  "googlevideo.com",
  "ytimg.com",
  "googleusercontent.com",
  "youtu.be",
  "netflix.com",
  "hulu.com",
  "primevideo.com",
  "disneyplus.com",
  "apple.com/tv",
  "hbomax.com",
  "vimeo.com",
  "dailymotion.com",
  "itunes.apple.com",
  "music.apple.com",
  "spotify.com",
  "music.amazon.com",
  "tidal.com",
  "deezer.com",
  "pandora.com",
  "soundcloud.com"
];

