import HttpService from '../HttpService'

export async function signInAdmin(token, data) {
    return await HttpService.account().setPath('/auth/admin-signin').setToken(token).setBody(data).post()
}

export async function signIn(data) {
    return await HttpService.account().setPath('/auth/signin').setBody(data).post()
}

export async function signUp(data) {
    return await HttpService.account().setPath('/auth/signup').setBody(data).post()
}

export async function fetchUserInfo(token) {
    return await HttpService.account().setPath('/me').setToken(token).get()
}

export async function signUpFree(data) {
    return await HttpService.account().setPath('/auth/free-signup-api').setBody(data).post()
}

export async function checkOtp(data, headers) {
    return await HttpService.account().setPath('/auth/check-otp').setHeaders(headers).setBody(data).post()
}

export async function resendOtp(data, headers) {
    return await HttpService.account().setPath('/auth/resend-otp').setHeaders(headers).setBody(data).post()
}

export async function recoverPassword(data) {
    return await HttpService.account().setPath('/auth/forget').setBody(data).post()
}

export async function setPasswordFree(data, headers) {
    return await HttpService.account().setPath('/auth/free-signup').setHeaders(headers).setBody(data).post()
}

export async function resetPassword(data) {
    return await HttpService.account().setPath('/auth/reset-password').setBody(data).post()
}

export async function changePassword(token, data) {
    return await HttpService.account().setPath('/change-password').setToken(token).setBody(data).post()
}

export async function getSubscription(token) {
    const request = HttpService.payment().setPath('/user/web-subscription')
    if (token) request.setToken(token)
    return await request.get()
}

export async function getSubscriptionSettings(subscription) {
    let url = "/settings/";
    if ($nuxt.$auth.loggedIn) {
        if (subscription === "Free") {
            url += "free_member_minutes_per_task";
        } else {
            url += "pro_minutes_per_task";
        }
    } else {
        url += "guest_minutes_per_task";
    }
    return await HttpService.server().setPath(url).get()
}
