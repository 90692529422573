/**
 * Decimal adjustment of a number.
 *
 * @param   {String}    type    The type of adjustment.
 * @param   {Number}    value   The number.
 * @param   {Integer}   exp     The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number}            The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
}

export function round10(value, exp) {
  return decimalAdjust("round", value, exp);
}

export function getProperName(name) {
  // eslint-disable-next-line no-useless-escape
  return name.replace(/[\/\\#+$~%'"*?<>{}`]/g, "").replace(/\s+/, " ");
}

export function getFileName(url) {
  const name = url ? url.substr(1 + url.lastIndexOf("/")).split("?")[0] : "";

  if (name && name.lastIndexOf("#") > name.lastIndexOf(".")) {
    return name.substr(0, name.lastIndexOf("#"));
  } else {
    return name;
  }
}

export function getUrlExtension(url) {
  const origin = new URL(url).origin;
  const name = url.replace(origin, "").split("/").slice(-1).pop().split("?")[0];
  return name.includes(".") ? name.split(".").pop() : null;
}

export function getExt(url) {
  const ext = (url = getFileName(url)).substring(
    (Math.max(0, url.lastIndexOf(".")) || Infinity) + 1
  );
  return ext && typeof ext === "string" ? ext.toLowerCase() : "";
}

export function getExtFromFile(file) {
  return new Promise((resolve, reject) => {
    const blob = file.slice(0, 4);
    const reader = new FileReader();
    reader.onload = (event) => {
      const uint = new Uint8Array(event.target.result);
      const bytes = [];
      uint.forEach((byte) => bytes.push(byte.toString(16)));
      const ext = getExtensionFromFileSignature(bytes.join("").toUpperCase());
      reader.abort();
      resolve(ext);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

function getExtensionFromFileSignature(signature) {
  const mp3Signatures = ["FFFB", "FFFA", "FFF3", "FFF2", "FFE3", "494433"];
  if (mp3Signatures.some((el) => signature.startsWith(el))) {
    return "mp3";
  }
  return "";
}

export function isMobile() {
  const agent = navigator.userAgent || navigator.vendor || window.opera;

  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      agent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      agent.substr(0, 4)
    )
  );
}

export function progressBarClass(status) {
  let barClass = "";
  switch (status) {
    case 0:
    case 1:
      barClass = "progress-bar-striped progress-bar-warning";
      break;
    case 2:
      barClass = "progress-bar-striped progress-bar-success";
      break;
    case 3:
      barClass = "progress-bar-striped progress-bar-warning";
      break;
    case 4:
      barClass = "progress-bar-success";
      break;
    case 5:
      barClass = "progress-bar-danger";
      break;
  }
  return barClass;
}

export function formatFormula(formula) {
  return formula
    .replace("*", " x ")
    .replace("+", " + ")
    .replace("-", " - ")
    .replace("/", " ÷ ");
}

export function truncate(n, len) {
  if (n) {
    if (n.length > len) {
      return `${n.substr(0, len / 2 - 1)}...${n.substr(-(len / 2 + 1))}`;
    }
    return n;
  } else {
    return "";
  }
}

export function truncateEnd(n, len) {
  if (n) {
    if (n.length > len) {
      return `${n.substr(0, len)}..`;
    }
    return n;
  } else {
    return "";
  }
}

export function includes(arr, key, value) {
  let found = false;
  arr.forEach((item) => {
    if (
      item[key] &&
      value &&
      typeof value === "string" &&
      item[key].toLowerCase() === value.toLowerCase()
    ) {
      found = item;
    }
  });
  return found;
}

export function getAOIds(ao) {
  let aoIds = "";
  ao.forEach((group) => {
    group.data.forEach(({ _id }) => {
      aoIds += _id;
    });
  });
  return aoIds;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}
export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function uuidv4() {
  return "xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export default {
  decimalAdjust,
  round10,
  formatFormula,
};

export function chunkArrayInGroups(arr, size) {
  const myArray = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export function bookmark(title, url) {
  if (window.sidebar && window.sidebar.addPanel) {
    // Firefox <23
    window.sidebar.addPanel(document.title, window.location.href, "");
  } else if (window.external && "AddFavorite" in window.external) {
    // Internet Explorer
    window.external.AddFavorite(location.href, document.title);
    // eslint-disable-next-line no-mixed-operators
  } else if (
    (window.opera && window.print) ||
    (window.sidebar && !(window.sidebar instanceof Node))
  ) {
    // Opera <15 and Firefox >23
    /**
     * For Firefox <23 and Opera <15, no need for JS to add to bookmarks
     * The only thing needed is a `title` and a `rel="sidebar"`
     * To ensure that the bookmarked URL doesn't have a complementary `#` from our trigger's href
     * we force the current URL
     */
    // eslint-disable-next-line no-undef
    triggerBookmark
      .attr("rel", "sidebar")
      .attr("title", document.title)
      .attr("href", url);
    return true;
  } else {
    // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
    alert(
      "You can add this page to your bookmarks by pressing " +
        (navigator.userAgent.toLowerCase().includes("mac")
          ? "Command/Cmd"
          : "CTRL") +
        " + D on your keyboard."
    );
  }
  // If you have something in the `href` of your trigger
  return false;
}

export function copyToClipBoard(text) {
  try {
    navigator.clipboard.writeText(text)
  } catch (e) {
    console.log('Error: ', e.message)
  }
}


export function capitalizeTheFirstLetterOfEachWord(words) {
  const separateWord = words.toLowerCase().split(" ");
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
}

export function limitString(str, limit) {
  if (str.length > limit) {
    return str.slice(0, limit - 2) + "..";
  }
  return str;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function clearToast() {
  const elems = document.querySelector(".swal2-container");
  if (elems) {
    elems.remove();
  }
}

export function showGooglePermissionError() {
  if (window.timerObj) {
    clearTimeout(window.timerObj);
  }
  // eslint-disable-next-line no-undef
  $nuxt.$store.commit("setAlert", {
    type: "danger",
    sticky: true,
    // eslint-disable-next-line no-undef
    msg: $nuxt._i18n
      .t("google_permission_error")
      .replace(
        "REGRANT",
        '<a href="#" onclick="getGooglePermission()">' +
          $nuxt._i18n.t("regrant_permission") +
          "</a>"
      )
      .replace(
        "SCREENSHOT",
        '<a href="/img/fix-google-permission.png" target="_blank">' +
          $nuxt._i18n.t("screenshot") +
          "</a>"
      ),
  });
}

export function generateRandomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)(?:\.(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)*(?:\.(?:[a-z\u00A1-\uFFFF]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export function splitStringInHalf(string) {
  let middle = Math.floor(string.length / 2);
  let first = string.slice(0, middle);
  let last = string.slice(middle);
  return { first, last };
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, "font-weight") || "normal";
  const fontSize = getCssStyle(el, "font-size") || "16px";
  const fontFamily = getCssStyle(el, "font-family") || "Times New Roman";

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function greatestDivisibleBy(num, divider) {
  // Find the greatest divisible by 3 by starting at the input number
  // and decrementing by 1 until we find a number that is divisible by 3
  let divisible = num;
  while (divisible % divider !== 0) {
    divisible -= 1;
  }
  return divisible;
}


export function capitalizeUnitName (unit, name) {
  if (!unit) return ''
  if (!unit[name]) return unit.common_name
  return unit[name] === unit.abbreviation ? unit.abbreviation : capitalizeFirstLetter(unit[name])
}

export function sortArrayByName(array) {
  array.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });

  return array;
}

export function sortArrayByNameReverse(array) {
  array.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return 1; // Sort in reverse order (Z to A)
    } else if (nameA > nameB) {
      return -1;
    } else {
      return 0;
    }
  });

  return array;
}


export function checkAlphabeticalOrder(arr) {
  const ascSorted = sortArrayByName([...arr]);
  const descSorted = sortArrayByNameReverse([...arr]);

  if (JSON.stringify(ascSorted) == JSON.stringify(arr)) return 1;
  if (JSON.stringify(descSorted) == JSON.stringify(arr)) return 0;
  return -1;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const pauseExecution = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));


export function stringToRegex(regexString) {
  // Remove the leading and trailing slashes (if present) so we can build a regex object
  const strippedRegexString = regexString.replace(/^\/|\/$/g, '').replace(/(\/g)$/, '');
  // Use the RegExp constructor to create a RegExp object
  return new RegExp(strippedRegexString);
}

export function calculateRatio(num_1, num_2){
  for(let num=num_2; num>1; num--) {
      if((num_1 % num) == 0 && (num_2 % num) == 0) {
          num_1=num_1/num;
          num_2=num_2/num;
      }
  }
  return [`${num_1}`, `${num_2}`];
}

function float2rat(x) {
  tolerance = 1.e-4;
  h1=1; h2=0;
  k1=0; k2=1;
  b = x;
  do {
      a = Math.floor(b);
      aux = h1; h1 = a*h1+h2; h2 = aux;
      aux = k1; k1 = a*k1+k2; k2 = aux;
      b = 1/(b-a);
  } while (Math.abs(x-h1/k1) > x*tolerance);
  
  return h1+"/"+k1;
}