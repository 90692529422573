/* eslint-disable no-undef */
import { getIp } from "~/fc/Client";

export async function initiateFreshDesk() {
  await waitForFreshworksWidget();
  await loadFreshdeskWidget();
}

function isFreshworksWidgetLoaded() {
  if (!window.FreshworksWidget || !FreshworksWidget) {
    return false;
  }
  return true;
}

async function loadFreshdeskWidget() {
  const prefill = await generateContactFormData();

  FreshworksWidget("prefill", "ticketForm", prefill);
  FreshworksWidget("hide", "ticketForm", [
    "custom_fields.cf_user_id",
    "custom_fields.cf_user_ip",
    "custom_fields.cf_page",
  ]);
}

async function generateContactFormData() {
  const ip = await getIp();
  const prefill = {};
  const customFields = {};
  const user = $nuxt.$auth.User;

  customFields.cf_user_ip = ip;
  customFields.cf_page = $nuxt.$route.path;
  if (user && user.id) {
    prefill.email = user.email;
    customFields.cf_user_id = user.id;
  }
  prefill.custom_fields = customFields;

  return prefill;
}

export function updateFreshDeskPageData(ctx) {
  if (!isFreshworksWidgetLoaded()) return;
  const route = ctx.$route.path;
  FreshworksWidget("prefill", "ticketForm", {
    custom_fields: {
      cf_page: route,
    },
  });
}

export function updateFreshDeskUserData() {
  if (!isFreshworksWidgetLoaded()) return;
  const user = $nuxt.$auth.User;
  FreshworksWidget("prefill", "ticketForm", {
    email: user.email,
    custom_fields: {
      cf_user_id: user.id,
    },
  });
}

async function waitForFreshworksWidget() {
  if (!isFreshworksWidgetLoaded()) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    return waitForFreshworksWidget();
  }
  return FreshworksWidget;
}

export async function resetWidget() {
  if (!isFreshworksWidgetLoaded()) return;
  FreshworksWidget("hide");
  const ip = await getIp();
  FreshworksWidget("destroy");
  FreshworksWidget("identify", "ticketForm", {
    description: "\n\n### Type your message above ###\n" + "\nUser IP: " + ip,
  });
  FreshworksWidget("boot");
  FreshworksWidget("show");
}

export function openWidget() {
  FreshworksWidget("open");
}

export function closeWidget() {
  FreshworksWidget("close");
}
